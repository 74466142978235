import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import style from './header.module.scss';

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `white`,
      marginBottom: `1.45rem`
    }}
  >
    <div className={style.headerContent}>
      <h1 style={{ margin: 0 }}>
        <Link
          to='/'
          style={{
            textDecoration: `none`,
            color: `#2c97e8`
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <div>
        <Link className={style.topNavLink} to='/docs/'>
          Docs
        </Link>
        <Link className={style.topNavLink} to='/blog/'>
          Blog
        </Link>
        <a className={style.topNavLink} href='https://manage.remark.ninja'>
          Login / Sign up
        </a>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
